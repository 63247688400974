import { defineStore } from "pinia";
import { useWishlist } from "./wishlist";
import { useModal } from "./modal";
import { useNotify } from "./notifications";
import { type IUser, type IUserState } from "./entites/user.interface";
import { useAPI } from "~/composables/useAPI";

export const useUser = defineStore("user", {
  state: (): IUserState => ({
    userId: "",
    email: "",
    role: "",
    firstName: "",
    lastName: "",
    image: "",
    picture: "",
    locale: "",
    createdAt: new Date(),
    updatedAt: new Date(),
  }),
  actions: {
    async getMyInfo() {
      return await useAPI()
        .user.getMyUser()
        .then((res) => {
          this.setUserInfo(res as IUser);
        });
    },
    async update() {
      return await useAPI()
        .user.updateMyUser(this)
        .then((res) => {
          this.setUserInfo(res as IUser);
          useNotify().show("notifications.user.updated", "", true);
        });
    },
    async delete() {
      return await useAPI()
        .user.deleteMyUser()
        .then((isDeleted) => {
          if (isDeleted) {
            useNotify().show("notifications.user.deleted", "", true);
          }
        });
    },
    async privateMode() {
      await useAPI()
        .user.privateMode()
        .then((res) => {
          if (res === true) {
            useNotify().show("notifications.user.private_mode", "", true);
          }
        });
    },
    async resetPassword(
      password: string,
      recaptchaToken: string,
      token: string,
    ) {
      return await useAPI()
        .user.resetPassword(password, recaptchaToken, token)
        .then((res) => {
          if (res === true) {
            useNotify().show(
              "pages.auth.reset_password.password_change_success",
              "",
              true,
            );
            return true;
          } else {
            useNotify().show("pages.auth.reset_password.failed", "", false);
            return false;
          }
        });
    },
    async forgotPassword(email: string, recaptchaToken: string) {
      return await useAPI()
        .user.forgotPassword(email, recaptchaToken)
        .then((res) => {
          if (res === true) {
            useNotify().show(
              "pages.auth.forgot_password.password_reset_success",
              "",
              true,
            );
            return true;
          } else {
            useNotify().show(
              "pages.auth.forgot_password.password_reset_failed",
              "",
              false,
            );
            return false;
          }
        });
    },
    reset() {
      this.$reset();
      useWishlist().reset();
      useModal().reset();
    },
    setUserInfo(user: IUser) {
      this.$patch(user);
      this.$patch({ image: user?.picture ?? "" });
    },
  },
  getters: {
    getFullName(): string {
      return (this.firstName ?? "") + " " + (this.lastName ?? "");
    },
    getEmail(): string {
      return this.email;
    },
    getId(): string {
      return this.userId;
    },
    isLoggedIn(): boolean {
      return useAuthState().status.value === "authenticated";
    },
    getImage(): string {
      return this.image;
    },
  },
});
